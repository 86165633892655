import {defineStore} from "pinia"
import ApiService from "../service/ApiService"

export const useAccountStore = defineStore('AccountStore', {
    state: () => ({
        hvpHideState: false
    }),
    getters: {
        hvpHideState(state) {
              return state.hvpHideState
        }
    },
    actions: {
        setHvpShowState(hideState) {
            return ApiService.setHvpShowState(hideState)
                .then(response => {
                    return response
                })
        }
    }
})