<template>
  <span
      class="uk-margin-small-left uk-link"
      :uk-tooltip="$t('text.hide-business-price-info')"
      @click="switchDisplayAndSave"
  ><i class="fa-regular fa-display"></i></span>
</template>

<script>
import {useAccountStore} from "../store/AccountStore.js";

export default {
  name: "FormOrder",
  setup() {
    const accountStore = useAccountStore()

    return {
      accountStore,
    }
  },
  data() {
    return {
      priceElements: null,
      showLink: true,
      elementOriginContent: ''
    };
  },
  mounted() {
    this.priceElements = document.getElementsByClassName("ws-price-info-container");
    Array.from(document.getElementsByClassName('ws-price-uvp-container')).forEach((element) => {
      this.elementOriginContent = element.innerHTML;
    })
    if (this.hvpShowState == '1') {
      this.showLink = true;
      this.switchDisplay()
    }
  },
  methods: {
    switchDisplay() {

      Array.from(this.priceElements).forEach((element) => {
        element.style.display = this.showLink ? "none" : "inline";
      });
      Array.from(document.getElementsByClassName('ws-price-uvp-container')).forEach((element) => {

        if (this.showLink) {
          element.style.fontWeight = 'bold'
          element.style.fontSize = '1.5rem'
        } else {
          element.style.fontWeight = 'normal'
          element.style.fontSize = 'unset'
        }
      });
      this.showLink = !this.showLink;
    },
    switchDisplayAndSave() {
      this.accountStore.setHvpShowState(this.showLink).then((response) => {
        if (response.data.success) {
          this.switchDisplay()
        }
      })
    }
  }
}

</script>